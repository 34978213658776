import Api from "@/modules/axios";

import { mapGetters } from "vuex";

export default {
  data() {
    return {
      slides: {},
      showFilterSidebar: false,
      promosAreLoading: false,
      newsAreLoading: false,
      allProductsAreLoading: false,
      promos: [],
      news: [],
      allProducts: [],
      currentPage: 1,
      pageSize: 16,
      totalProducts: 0,
    };
  },
  computed: {
    ...mapGetters(["preferences"]),
    viewMode: {
      get() {
        return this.preferences?.viewMode || "grid";
      },
      set(val) {
        this.$store.commit("updatePreferences", { viewMode: val });
      },
    },
  },
  methods: {
    /**
     * Récupère les sliders et sépare les larges de petits.
     * @param {String} page sur laquelle les slides doivent figurer.
     */
    fetchSliders(page) {
      Api().get("/slider/")
        .then(({ data: { results } }) => {
          if (!results) {
            this.slides = { small: [], large: [] };
          } else {
            let slidesToDisplay = results.filter((slide) => (slide.page === page));
            if (!this.isHorsMarche) {
              slidesToDisplay = slidesToDisplay.filter((s) => (
                this.organismeActuel.marches.some((marche) => s.marches.includes(marche.id))
              ));
            } else {
              slidesToDisplay = slidesToDisplay.filter((slide) => slide.sans_marche);
            }
            this.slides = {
              small: slidesToDisplay.filter((slide) => slide.petite_taille),
              large: slidesToDisplay.filter((slide) => !slide.petite_taille),
            };
          }
        });
    },
    /**
     * Réinitialise les filtres, actualise la page et met la pagination sur 1.
     */
    resetFilters() {
      this.additionalFilters.forEach(({ options }) => {
        options.forEach((option) => {
          this.$set(option, "_checked", false);
        });
      });
      this.$router.push({ name: this.$route.name });
      this.currentPage = 1;
    },
    /**
     * Récupère des nouveaux produits de façon aléatoire en fonction d'une pondération définie en back.
     * @param {String} article Définit la base de l'URL pour récupérer les articles.
     */
    fetchRandomNouveautes(article) {
      this.newsAreLoading = true;
      Api().get(`/${article}/randoms/?nb=4&only_news=1`)
        .then(({ data }) => {
          this.news = data;
          this.newsAreLoading = false;
        });
    },
    /**
     * Récupère les promotions.
     * @param {String} article Définit la base de l'URL pour récupérer les articles.
     */
    fetchRandomPromotions(article) {
      this.promosAreLoading = true;
      Api().get(`/${article}/randoms/?nb=4&only_promos=1`)
        .then(({ data }) => {
          this.promos = data;
          this.promosAreLoading = false;
        });
    },
  },
};
