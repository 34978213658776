<template>
  <PageContent
    class="fournitures-papeterie"
    :nav-titles="currentPage === 1 ? navTitles : null"
  >
    <template #header-left>
      <ButtonPopover
        v-model="isPopoverCategoriesOpen"
        variant="solid"
        color="secondary"
        :label="$t('filtre.categories')"
        icon="left"
        placement="bottom-left"
        trigger="click"
        :disabled="!categories.length"
      >
        <template #left-icon>
          <UilListUiAlt />
        </template>
        <div
          v-if="categories.length"
          class="text-medium"
        >
          <ul id="categories">
            <li
              v-for="cat in categories"
              :key="cat.id"
              :class="{ active: selectedCategory?.id === cat.id, }"
              @mouseover="selectedCategory = cat"
            >
              <p class="flex-vcenter">
                <UilCheck />
                <span>{{ cat.libelle }}</span>
              </p>
              <UilAngleRightB />
            </li>
          </ul>
          <component
            :is="pub.lien ? 'a' : 'figure'"
            v-if="pub"
            id="pub"
            v-bind="pubAttr"
          >
            <img
              :src="pub.illustration"
              :alt="$t('general.alt-image-illustration-pub')"
            />
          </component>
        </div>
        <ul
          v-if="selectedCategory"
          id="subcategory"
          class="text-medium"
        >
          <li
            v-for="subcat in selectedCategory.sous_categories"
            :key="subcat.id"
          >
            <button
              class="options-list-item-btn"
              @click="selectSousCateg(subcat)"
            >
              {{ subcat.libelle }}
            </button>
          </li>
        </ul>
      </ButtonPopover>
    </template>

    <template #header-right>
      <ButtonGroup :selected-id="viewMode">
        <ButtonClassic
          id="grid"
          v-matomo-log-click="['viewMode', 'grid', ]"
          variant="solid"
          icon="left"
          @click="viewMode = 'grid'"
        >
          <template #left-icon>
            <UilTable />
          </template>
        </ButtonClassic>
        <ButtonClassic
          id="list"
          v-matomo-log-click="['viewMode', 'list', ]"
          variant="solid"
          icon="left"
          @click="viewMode = 'list'"
        >
          <template #left-icon>
            <IconListView />
          </template>
        </ButtonClassic>
      </ButtonGroup>
      <!-- TODO: remettre les filtres quand on saura lesquels + après refactor des filtres -->
      <!-- <ButtonClassic
        :label="$t('filtre.filtres')"
        color="primary"
        :variant="showFilterSidebar ? 'solid' : 'ghost'"
        :class="{ active: showFilterSidebar, }"
        icon="right"
        :disabled="allProductsAreLoading"
        @click="showFilterSidebar = !showFilterSidebar"
        >
        <template #right-icon>
        <UilFilter v-if="!showFilterSidebar" />
        <UilTimes v-else />
        </template>
        </ButtonClassic> -->
    </template>

    <template #aside-content>
      <FilterSidebar
        v-if="showFilterSidebar && allFilters.length"
        :title="$t('recherche.affiner-votre-recherche')"
        :possible-filters="allFilters"
      />
    </template>

    <template #content>
      <section
        v-if="currentPage === 1"
        id="focus"
      >
        <HomeMessages />
        <div
          v-if="(slides.large && slides.large.length) || (slides.small && slides.small.length)"
          id="sliders"
          class="grid-zone"
        >
          <!-- Slider large -->
          <Slider
            v-if="slides.large && slides.large.length"
            pagination-small
          >
            <template #slider-items>
              <SliderCard
                v-for="slide in slides.large"
                :key="slide.id"
                :title="{ text: slide.titre, color: slide.titre_couleur, }"
                :headline="{ text: slide.accroche, color: slide.accroche_couleur, }"
                :description="{ text: slide.description, color: slide.description_couleur, }"
                :background="{ color: slide.fond_couleur, image: slide.fond_image, }"
              >
                <template
                  v-if="slide.logo"
                  #header-image
                >
                  <img
                    :src="slide.logo"
                    :alt="$t('general.alt-logo-editeur')"
                  />
                </template>
                <template
                  v-if="slide.illustration"
                  #illustration
                >
                  <img
                    :src="slide.illustration"
                    :alt="$t('general.alt-image-illustration')"
                  />
                </template>
                <template #buttons>
                  <ButtonClassic
                    v-if="slide.btn_1_lien"
                    v-matomo-log-click="['slider-btn-1', slide.titre, slide.btn_1_label, ]"
                    balise-type="a"
                    :label="slide.btn_1_label"
                    variant="solid"
                    color="primary"
                    icon="left"
                    :ext-link="slide.btn_1_lien"
                  >
                    <template #left-icon>
                      <UilArrowRight />
                    </template>
                  </ButtonClassic>
                  <ButtonClassic
                    v-if="slide.btn_2_lien"
                    v-matomo-log-click="['slider-btn-2', slide.titre, slide.btn_2_label, ]"
                    balise-type="a"
                    :label="slide.btn_2_label"
                    variant="solid"
                    color="primary"
                    icon="left"
                    :ext-link="slide.btn_2_lien"
                  >
                    <template #left-icon>
                      <UilArrowRight />
                    </template>
                  </ButtonClassic>
                </template>
              </SliderCard>
            </template>
          </Slider>

          <!-- Slider small -->
          <Slider
            v-if="slides.small && slides.small.length"
            :interval="5"
            small
            pagination-small
          >
            <template #slider-items>
              <SliderCard
                v-for="slide in slides.small"
                :key="slide.id"
                :title="{ text: slide.titre, color: slide.titre_couleur, }"
                :headline="{ text: slide.accroche, color: slide.accroche_couleur, }"
                :description="{ text: slide.description, color: slide.description_couleur, }"
                :background="{ color: slide.fond_couleur, image: slide.fond_image, }"
              >
                <template
                  v-if="slide.logo"
                  #header-image
                >
                  <img
                    :src="slide.logo"
                    :alt="$t('general.alt-logo-editeur')"
                  />
                </template>
                <template
                  v-if="slide.illustration"
                  #illustration
                >
                  <img
                    :src="slide.illustration"
                    :alt="$t('general.alt-image-illustration')"
                  />
                </template>
                <template #buttons>
                  <ButtonClassic
                    v-if="slide.btn_1_lien"
                    v-matomo-log-click="['slider-btn-1', slide.titre, slide.btn_1_label, ]"
                    balise-type="a"
                    :label="slide.btn_1_label"
                    variant="solid"
                    color="primary"
                    size="small"
                    icon="left"
                    :ext-link="slide.btn_1_lien"
                  >
                    <template #left-icon>
                      <UilArrowRight />
                    </template>
                  </ButtonClassic>
                  <ButtonClassic
                    v-if="slide.btn_2_lien"
                    v-matomo-log-click="['slider-btn-1', slide.titre, slide.btn_2_label, ]"
                    balise-type="a"
                    :label="slide.btn_2_label"
                    variant="solid"
                    color="primary"
                    size="small"
                    icon="left"
                    :ext-link="slide.btn_2_lien"
                  >
                    <template #left-icon>
                      <UilArrowRight />
                    </template>
                  </ButtonClassic>
                </template>
              </SliderCard>
            </template>
          </Slider>
        </div>
      </section>

      <template v-if="currentPage === 1">
        <section
          v-if="!promosAreLoading && promos.length"
          id="promotions"
        >
          <h2 class="s5 title-separator">
            <span class="bold">{{ $t('filtre.promotions') }}</span>
            <!-- TODO: remettre les filtres quand on saura lesquels + après refactor des filtres -->
            <!-- <router-link
              v-matomo-log-click="['toutes-promotions', ]"
              class="text-medium underline"
              :to="{ query:{ 'promotion': 1, }, }"
              >
              {{ $t('fournitures-papeterie.toutes-les-promotions') }}
              </router-link> -->
          </h2>
          <div
            v-if="!promosAreLoading"
            class="grid-zone"
          >
            <ProductItem
              v-for="(item, i) in promos"
              :key="item.id"
              v-model="promos[i]"
              :view="viewMode"
              small
            />
          </div>
          <InfiniteLoader v-else />
        </section>

        <section
          v-if="!newsAreLoading && news.length"
          id="nouveautes"
        >
          <h2 class="s5 title-separator">
            <span class="bold">{{ $t('filtre.nouveautes') }}</span>
            <!-- TODO: remettre les filtres quand on saura lesquels + après refactor des filtres -->
            <!-- <router-link
              v-matomo-log-click="['toutes-nouveautés', ]"
              class="text-medium underline"
              :to="{ query:{ 'nouveaute': 1, }, }"
              >
              {{ $t('ressource-numerique.toutes-les-nouveautes') }}
              </router-link> -->
          </h2>
          <div
            v-if="!newsAreLoading"
            class="grid-zone"
          >
            <ProductItem
              v-for="(item, i) in news"
              :key="item.id"
              v-model="news[i]"
              :view="viewMode"
              small
            />
          </div>
          <InfiniteLoader v-else />
        </section>
      </template>

      <section id="tout">
        <!-- v-if="currentPage === 1" -->
        {{ /* TODO: A remettre quand on aura des filtres propres */ }}
        <h2 class="s5 title-separator">
          <span class="bold">
            {{ $t('fournitures-papeterie.nos-produits-fournitures-et-papeterie') }}
            <template v-if="selectedSubCat">
              {{ /* A retirer quand on aura des filtres propres */ }}
              : {{ selectedSubCat.libelle }}
            </template>
          </span>
          <ButtonClassic
            v-if="selectedSubCat"
            :label="$t('action.reinitialiser')"
            variant="special"
            color="primary"
            icon="right"
            size="small"
            @click="selectSousCateg()"
          >
            <template #right-icon>
              <IconTrashReset />
            </template>
          </ButtonClassic>
        </h2>
        <div v-if="!allProductsAreLoading && allProducts.length">
          <div
            id="all_products"
            class="grid-zone"
          >
            <template v-if="allProducts.length">
              <ProductItem
                v-for="(item, i) in allProducts"
                :key="item.id"
                v-model="allProducts[i]"
                :view="viewMode"
                small
              />
            </template>
          </div>
          <div class="pagination-container">
            <Pagination
              v-if="totalProducts > pageSize"
              v-model="currentPage"
              :total-rows="totalProducts"
              :per-page="pageSize"
              @change="(page) => $router.push({ query: { ...$route.query, page, }, })"
            />
          </div>
        </div>
        <ErrorPage
          v-else-if="!allProductsAreLoading"
          :title="$t('recherche.aucun-resultat')"
          :content="$t('recherche.aucun-produit-correspondant')"
        >
          <template #custom-icon>
            <img
              :src="require('@lde/core_lde_vue/dist/assets/media/illus/illus_zero_resultat.svg')"
              :alt="$t('recherche.illustration-zero-resultat')"
            />
          </template>
        </ErrorPage>

        <div v-else>
          <InfiniteLoader />
        </div>
      </section>
    </template>
  </PageContent>
</template>

<script>
import {
  ButtonClassic,
  ButtonGroup,
  ButtonPopover,
  InfiniteLoader,
  Pagination,
  Slider,
  SliderCard,
  PageContent,
  IconTrashReset,
} from "@lde/core_lde_vue";

import ProductItem from "@/components/products/ProductItem.vue";
import FilterSidebar from "@/components/search/filters/FilterSidebar.vue";
import ErrorPage from "@/components/layout/ErrorPage.vue";
import HomeMessages from "@/components/home/HomeMessages.vue";

import IconListView from "@/components/icons/IconListView.vue";
import {
  UilListUiAlt,
  UilTable,
  // UilFilter,
  // UilTimes,
  UilArrowRight,
  UilCheck,
  UilAngleRightB,
} from "@iconscout/vue-unicons";

import ListProducts from "@/mixins/mixinListProducts";

import Api from "@/modules/axios";

import { mapGetters } from "vuex";

/**
 * Vue de la liste des fournitures.
 */
export default {
  name: "FournituresPapeterie",
  components: {
    PageContent,
    SliderCard,
    Slider,
    ButtonClassic,
    ButtonGroup,
    ButtonPopover,
    ProductItem,
    Pagination,
    ErrorPage,
    HomeMessages,
    FilterSidebar,
    IconTrashReset,
    IconListView,
    InfiniteLoader,
    UilListUiAlt,
    UilTable,
    // UilFilter,
    // UilTimes,
    UilArrowRight,
    UilCheck,
    UilAngleRightB,
  },
  mixins: [ListProducts],
  data() {
    return {
      isPopoverCategoriesOpen: false,
      categories: [],
      selectedCategory: null,
      selectedSubCat: null,
      pub: null,
    };
  },
  computed: {
    ...mapGetters(["organismeActuel", "isHorsMarche", "sideCheckedFilters", "filters"]),
    navTitles() {
      const res = [];

      if ((this.slides.large?.length) || (this.slides.small?.length)) {
        res.push({ label: this.$t("general.focus"), anchorId: "focus" });
      }

      if (!this.newsAreLoading && this.news.length) {
        res.push({ label: this.$t("filtre.nouveautes"), anchorId: "nouveautes" });
      }

      if (!this.promosAreLoading && this.promos.length) {
        res.push({ label: this.$t("filtre.promotions"), anchorId: "promotions" });
      }

      res.push({ label: this.$t("general.tout"), anchorId: "tout" });
      return res;
    },
    pubAttr() {
      if (this.pub?.lien) {
        return {
          class: "link",
          href: this.pub.lien,
          target: "_blank",
          rel: "noreferrer noopener",
        };
      }
      return null;
    },
  },
  watch: {
    "$route.query.page": {
      handler() {
        this.fetchAllArticles(false, false);
      },
    },
  },
  created() {
    this.fetchSliders("fourniture");
    this.fetchPub();
    this.fetchAllArticles(false, false);
  },
  mounted() {
    this.fetchRandomNouveautes("fourniture");
    this.fetchRandomPromotions("fourniture");
    this.fetchCategories().then(() => {
      this.selectedCategory = this.categories[0];
    });
  },
  methods: {
    /**
     * Récupère "toutes les fournitures".
     * @param {Boolean} scroll Si true, scrolle jusqu'à la zone des produits.
     * @param {Boolean} resetPage Si true, affiche les résultats de la première page.
     * @return {Promise} Retourne toutes les fournitures.
     */
    fetchAllArticles(scroll = true, resetPage = true) {
      if (resetPage) {
        this.currentPage = 1;
        return this.$router.push({ query: { ...this.$route.query, page: 1 } });
      } if (this.$route.query?.page) {
        this.currentPage = parseInt(this.$route.query?.page, 10);
      }

      const params = {
        page_size: this.pageSize,
        page: this.currentPage,
        parent__isnull: true,
        lot: false,
        ordering: "prix_ht",
        famille: this.selectedSubCat?.id,
      };

      if (scroll) {
        window.location.href = "#tout";
      }

      this.allProductsAreLoading = true;

      // TODO: filtres comme pour ressources num
      const promise = Api().get("/fourniture/", { params })
        .then(({ data }) => {
          this.allProducts = data.results;
          this.totalProducts = data.count;
          this.allProductsAreLoading = false;
        });

      return promise.catch((error) => {
        console.error(error);
        this.$toast.error({
          title: this.$t("toast.erreur"),
          content: error.response?.data?.detail,
        });
      });
    },
    /**
     * Récupère toutes les catégories.
     */
    fetchCategories() {
      return Api().get("/fourniture/familles/")
        .then(({ data }) => {
          this.categories = data.familles;
        });
    },
    /**
     * Définit la sous-catégorie active.
     * @param {Object} subcat Sous-catégorie.
     */
    selectSousCateg(subcat) {
      this.selectedSubCat = subcat;
      this.fetchAllArticles(false, this.currentPage !== 1);
      this.selectedCategory = null;
    },
    /**
     * Récupère la pub active.
     */
    fetchPub() {
      Api().get("/pub_fourniture/")
        .then(({ data }) => {
          this.pub = data.results[0];
        });
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/views/_list_products.scss";
@use "@/assets/styles/views/_fournitures_papeterie.scss";
</style>
