<template>
  <form
    class="sidebar filters"
    @submit.prevent="submitFilters()"
  >
    <header>
      <h2 class="filters-title s5 light">
        {{ title }}
      </h2>
      <div class="reset-filter">
        <div
          v-if="checkedFilters.length"
          class="filters-list"
        >
          <Tag
            v-for="filter in allFiltersName"
            :key="filter.id"
            :tag-name="filter.libelle"
            delete-option
            @delete-tag="removeOptionFromTag(filter)"
          />
        </div>
        <ButtonClassic
          v-show="checkedFilters.length"
          variant="special"
          :label="$t('action.reinitialiser')"
          color="primary"
          icon="right"
          size="small"
          @click="resetFilter()"
        >
          <template #right-icon>
            <UilTrashAlt />
          </template>
        </ButtonClassic>
      </div>
    </header>

    <div class="filters-content">
      <InputClassic
        v-model="searchFilter"
        :placeholder="$t('filtre.trouvez-vos-filtres')"
        inline
        @change="searchChanged()"
      />
      <FilterSingle
        v-for="(filter, index) in (reducedFilters && reducedFilters.length ? reducedFilters : possibleFilters)"
        :key="`single-filters-${index}-${filter.slug}`"
        :is-collapsed="filter._collapse"
        :label="filter.nom"
        :options="filter.options.slice(0, endLimit) || []"
        :filter="filter.slug"
        :is-checked="isChecked"
        @collapse="(clps) => $set(filter, '_collapse', clps)"
        @scroll-end="handleScrollEnd(filter)"
        @change="handleChange({ ...$event, filter: filter.slug, })"
      />
    </div>

    <div class="filters-validation-container">
      <ButtonClassic
        class="filters-validation-button"
        :label="$t('filtre.valider-les-filtres')"
        variant="solid"
        color="primary"
        type="submit"
      />
    </div>
  </form>
</template>

<script>
import {
  ButtonClassic,
  InputClassic,
  Tag,
  FilterSingle,
  setAsideTop,
} from "@lde/core_lde_vue";
import { UilTrashAlt } from "@iconscout/vue-unicons";

/**
 * Affiche une barre latérale contenant des filtres de recherche.
 */
export default {
  name: "FilterSidebar",
  components: {
    FilterSingle,
    Tag,
    ButtonClassic,
    InputClassic,
    UilTrashAlt,
  },
  props: {
    /**
     * Titre de l'en-tête.
     */
    title: {
      type: String,
      default: "",
    },
    /**
     * Limite à afficher dans les options au fur et à mesure du chargement.
     */
    delimiter: {
      type: Number,
      default: 100,
    },
    /**
     * Filtres à afficher.
     */
    possibleFilters: {
      type: Array,
      default: () => [],
    },
    // /**
    //  * Tableau qui contient les filtres cochés.
    //  */
    activeFilters: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    /**
     * Déclenché à la validation (mise à jour) des filtres.
     */
    "update",
  ],
  data() {
    return {
      searchFilter: "",
      checkedFilters: [],
      reducedFilters: [],
      endLimit: this.delimiter,
    };
  },
  computed: {
    allFiltersName() {
      const filters = [];
      this.checkedFilters.forEach(({ slug, options }, index) => {
        options.forEach((id) => {
          const flt = this.possibleFilters.find((s) => s.slug === slug);
          if (flt) {
            const opt = flt.options.find((o) => o.id === id);
            if (opt) {
              filters.push({
                index,
                libelle: opt.libelle,
                id,
              });
            }
          }
        });
      });
      return filters;
    },
  },
  /**
   * À l'ouverture, on actualise les résultats avec ceux du store et de la queryString.
   */
  activated() {
    this.setAsideTop();
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.setAsideTop();
    });

    this.checkedFilters = this.activeFilters;
  },
  methods: {
    setAsideTop,
    /**
     * Permet de gérer le cochage/décochage d'une option de filtre.<br />
     * Note: On utilise $set pour ne pas perdre en réactivité
     * @typedef {Object} CheckPayload - L'objet représentant le param.
     * @param {CheckPayload}
     * @param {Boolean} value La valeur de la checkbox.
     * @param {String} option L'option cochée ou non.
     * @param {String} filter Le slug du filtre contenant l'option.
     *
     */
    handleChange({ value, option, filter }) {
      /** Index du filtre en local */
      let localFilterIndex = this.checkedFilters.findIndex((_filter) => _filter.slug === filter);

      /** Si on veut cocher l'option */
      if (value) {
        /** S'il n'y est pas, on l'initialise */
        if (localFilterIndex === -1) {
          localFilterIndex = this.checkedFilters.push({ slug: filter, options: [] }) - 1;
        }
        /**
         * Le filtre est dans le tableau local, on lui ajoute l'option que l'on veut cocher
         */
        this.addOption(localFilterIndex, option.id);
      } else if (this.checkedFilters[localFilterIndex]) {
        /** Si on veut décocher l'option ET que le tableau existe */
        this.removeOption(localFilterIndex, option.id);

        /**
         * Enfin, si le tableau des options ne contient plus rien, on supprime le filtre
         */
        if (!this.checkedFilters[localFilterIndex].options.length) {
          this.removeFilter(localFilterIndex);
        }
      }
    },
    /**
     * Gestion du scroll lorsqu'on arrive en bas des options.
     * @param {Object} Filter Catégorie du filtre avec tous ses éléments (checkboxes).
     */
    handleScrollEnd(filter) {
      if (filter.options.length > this.endLimit) {
        this.endLimit += this.delimiter;
      }
    },
    /**
     * Permet d'ajouter une option de filtre.
     * @param {Number} index L'index du filtre dans le tableau local.
     * @param {String} optionID L'id de l'option à ajouter.
     */
    addOption(index, optionID) {
      const filter = this.checkedFilters[index];
      filter.options.push(optionID);
    },
    /**
     * Permet de supprimer une option de filtre.
     * @param {Number} index L'index du filtre dans le tableau local.
     * @param {String} optionID L'id de l'option à supprimer.
     */
    removeOption(index, optionID) {
      const filter = this.checkedFilters[index];
      if (filter) {
        const options = filter.options.filter((id) => id !== optionID);
        filter.options = options;
      }
    },
    /**
     * Permet de supprimer une option de filtre via les tags.
     * @param {Object} tag Tag correspondant au filtre sélectionné.
     */
    removeOptionFromTag({
      id,
      index,
    }) {
      this.removeOption(index, id);
      if (!this.checkedFilters[index].options.length) {
        this.removeFilter(index);
      }
    },
    /**
     * Permet de supprimer un filtre avec ses options.
     * @param {Number} index L'index du filtre dans le tableau local.
     */
    removeFilter(index) {
      this.checkedFilters = this.checkedFilters.filter((_, _index) => index !== _index);
    },
    /**
     * Permet de savoir si une option est cochée ou non.
     * @param {String} id L'id de l'option.
     * @param {String} filter Le slug du fitre contenant l'option.
     * @returns {Boolean} Si l'option est cochée ou non.
     */
    isChecked(id, filter) {
      const filterArr = this.checkedFilters.find((_filter) => _filter.slug === filter);
      return filterArr && filterArr.options && filterArr.options.includes(id);
    },
    /**
     * Applique les filtres.
     */
    submitFilters() {
      /** Génération d'une queryString */
      const entries = this.checkedFilters
        .map((filter) => [filter.slug, filter.options.join(",")]);
      const query = Object.fromEntries(entries);

      /**
       * Pour chaque filtre on supprime les anciennes valeurs dans la query string actuelle.
       * De ce fait, Vue ne pourra pas throw l'erreur "Redundant Navigation".
       */
      const filterSlugs = this.possibleFilters.map((filter) => filter.slug);
      const queryEntries = Object
        .entries(this.$route.query)
        .filter(([key]) => !filterSlugs.includes(key));
      const oldQuery = Object.fromEntries(queryEntries);

      /** On push avec la nouvelle queryString */
      const newQuery = {
        ...oldQuery,
        ...query,
      };
      let routeCatalogues = false;
      if (this.$route.name.includes("catalogues_")) {
        switch (this.$route.name) {
          // switch pour préparer les filtres fournitures.
          case "catalogues_ressources_numeriques":
            newQuery.only = "numeriques";
            routeCatalogues = true;
            break;
          case "catalogues_livres_manuels_papier":
            newQuery.only = "papiers";
            routeCatalogues = true;
            break;
          default:
            break;
        }
        newQuery.search = "";
      }
      newQuery.page = 1;
      if (JSON.stringify(this.$route.newQuery) !== JSON.stringify(newQuery) || routeCatalogues) {
        this.$router.push({
          name: routeCatalogues ? "search" : this.$route.name,
          query: newQuery,
        });
      } else {
        // Reload la page sans erreur
        this.$store.commit("addSearchKey");
      }
      this.$emit("update");
    },
    /**
     * Réinitialise les filtres en décochant toutes les checkboxes.
     */
    resetFilter() {
      this.checkedFilters = [];
      // this.$store.commit("setSideCheckedFilters", []);
      this.submitFilters();
    },
    /**
     * Réduit les filtres affichés selon le texte tapé.
     * @param {Array} filters Tableau de filtres.
     * @returns {Array} Tableau de filtres filtrés.
     */
    reduceFilters(filters) {
      let arr = [];

      // Confond les accents et les majuscules d'une string
      // Voir https://stackoverflow.com/a/37511463
      const normalizeString = (str) => str.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "");
      filters.forEach((filtre, index) => {
        arr = [...arr, {
          _collapse: true,
          nom: filtre.nom,
          options: [],
          slug: filtre.slug,
        }];

        filtre.options.forEach((option) => {
          if (normalizeString(option.libelle).includes(normalizeString(this.searchFilter))) {
            arr[index].options.push(option);
          }
        });
      });
      return arr;
    },
    /**
     * Décoche les filtres et permet de choisir la méthode de réduction des filtres selon le contexte.
     */
    searchChanged() {
      this.reducedFilters = [];
      if (this.searchFilter !== "") {
        this.reducedFilters = this.reduceFilters(this.possibleFilters);
      }
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/search/filters/filter_sidebar.scss";
</style>
